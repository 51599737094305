<template>
  <div id="main">
    <div class="container-md">
      <i-navbar ref="nav"></i-navbar>

      <div
        id="bookList"
        class="mt-5 row"
        :class="{ 'd-none': pageType != 'list' }"
      >
        <div class="col-4" v-for="item in topicResearchList" :key="item.title">
          <div class="col book_card">
            <img :src="item.imageUrls" alt="..." />
            <div class="px-2 text-left">
              <div class="info">
                <span>{{ item.publishDate }}</span>
                <span class="float-right">作者 | {{ item.author }}</span>
              </div>
              <div class="title">
                <span
                  type="button"
                  @click="openBookInfo(item.title, item.subTitle)"
                  >{{ item.title }}</span
                >
                <p>{{ item.subTitle }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="bookInfo"
        class="mt-5 text-left"
        :class="{ 'd-none': pageType != 'info' }"
      >
        <div class="mt-5">
          <img :src="topicObject.bigImageUrls" alt="..." />
        </div>

        <div class="mt-3">
          <span class="title"> {{ topicObject.title }} </span>
        </div>

        <div class="mt-5">
          <span class="sub_title"> {{ topicObject.subTitle }} </span>
        </div>

        <hr />

        <div id="font_size" class="mt-2">
          <span class="date"> {{ topicObject.publishDate }}</span>
          <!-- <div class="text-right">
            <a
              href="#"
              class="mx-1"
              type="button"
              style="font-size: 20px"
              :class="{ active: curr_content_font_size_active[0] }"
              @click.prevent="setContentSize(0, '20px')"
              >A</a
            >
            <a
              href="#"
              class="mx-1"
              style="font-size: 18px"
              :class="{ active: curr_content_font_size_active[1] }"
              @click.prevent="setContentSize(1, '18px')"
              >A</a
            >
            <a
              href="#"
              class="mx-1"
              style="font-size: 16px"
              :class="{ active: curr_content_font_size_active[2] }"
              @click.prevent="setContentSize(2, '16px')"
              >A</a
            >
          </div> -->
        </div>

        <!-- author -->
        <div id="author" class="mt-5">
          作者 /
          <span> {{ topicObject.author }}</span>
        </div>

        <!-- content -->
        <div id="content" v-html="topicObject.content"></div>

        <hr class="bottom_hr" />

        <!-- referce & author -->
        <div id="ref_author_section">
          <!-- <div class="ref_section">
            <span class="number d-inline">【1】</span>
            <p class="d-inline">
              BBC新聞，〈狗狗眼神賣萌難以抗拒操縱人類的進化「伎倆」〉，https://www.bbc.com/zhongwen/trad
            </p>
          </div> -->

          <div class="author_section mb-5 row">
            <div class="col-4">
              <img
                v-if="topicObject.mainAuthor"
                :src="topicObject.mainAuthor.photo_url"
                alt="..."
              />
              <img
                v-else
                src="https://ijqq7w.ch.files.1drv.com/y4muqSlhzmyXsmNduGlnc6rqDseKi7taGrv6BvmSKCK7KMUNjVDJ86x-cccHHxvoFyH_CIRSdwxjW2CJia2vCq99HSQEeXVOWa3h0mTFtYyyboIyZwoM1XWDIX_BE7uKzjGsxAdHo0hB-cOjpU052gewg5bEu1Eq9kssJpUNxHRvlziwtZRxNUuWoW51xjGosPwzMuNTyod71CVjDKWpKue5w/7-1%20%E4%B8%BB%E9%A1%8C%E7%A0%94%E7%A9%B6(%E5%85%A7%E6%96%87)%20(1).png?psid=1"
                alt="..."
              />
            </div>
            <div class="col-8">
              <p v-if="topicObject.mainAuthor">作者 / {{ topicObject.mainAuthor.name }}</p>
              <p v-else>作者 / {{ topicObject.author }}</p>

              <p v-if="topicObject.mainAuthor">Email / {{ topicObject.mainAuthor.email }}</p>
              <p v-else>Email / --- </p>

              <p class="mt-4" v-if="topicObject.mainAuthor">
                {{ topicObject.mainAuthor.introduction }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      id="go_back_btn"
      type="button"
      class="btn btn-info"
      v-if="pageType != 'list'"
      @click="pageType = 'list'"
    >
      回到主題研究列表
    </button>
    <i-footer></i-footer>
  </div>
</template>

<script>
import navbar from '../components/Navbar.vue';
import footer from '../components/Footer.vue';

export default {
  mounted() {
    this.$refs.nav.isActiveList = [0, 0, 0, 0, 0, 0, 0, 0];
    this.$refs.nav.isActiveList[7] = 1;

    // console.log('query:', this.$route.query);
    // console.log('title:', this.$route.query.title);
    // console.log('subTitle:', this.$route.query.subTitle);

    const topicResearchListAPI = `${process.env.VUE_APP_APIPATH}api/topic-research-list/`;
    this.$http.get(topicResearchListAPI).then((response) => {
      console.log(response.data);
      this.topicResearchList = response.data;
      this.topicResearchList.forEach((item, index) => {
        const imagrSrc = this.topicResearchList[index].imageUrls;
        this.topicResearchList[
          index
        ].imageUrls = `${process.env.VUE_APP_IMAGE_APIPATH}topic_research/${imagrSrc}`;
      });
      console.log(this.topicResearchList);
    });

    if (this.$route.query.title && this.$route.query.subTitle) {
      const title = `${this.$route.query.title}`;
      const subTitle = `${this.$route.query.subTitle}`;
      console.log(title, subTitle);
      this.openBookInfo(title, subTitle);
    }
  },
  components: {
    'i-navbar': navbar,
    'i-footer': footer,
  },
  data() {
    return {
      pageType: 'list',
      pageTypeList: ['list', 'info'],
      currTopicObjectIndex: 0,
      contentFontSize: '20px',
      curr_content_font_size_active: [1, 0, 0],
      topicResearchList: [],
      topicObject: {},
    };
  },
  methods: {
    openBookInfo(title, subTitle) {
      this.pageType = 'info';
      console.log(title, subTitle);
      const topicResearchInfoAPI = `${process.env.VUE_APP_APIPATH}api/topic-research-info/?title=${title}&sub_title=${subTitle}`;
      console.log(topicResearchInfoAPI);
      this.$http.get(topicResearchInfoAPI).then((response) => {
        console.log(response.data);
        this.topicObject = { ...response.data[0] };
        const imagrSrc = this.topicObject.bigImageUrls;
        this.topicObject.bigImageUrls = `${process.env.VUE_APP_IMAGE_APIPATH}topic_research/${imagrSrc}`;
        console.log(this.topicObject);
      });
    },
    setContentSize(activeIndex, fontSize) {
      this.contentFontSize = fontSize;
      this.curr_content_font_size_active = [0, 0, 0];
      this.curr_content_font_size_active[activeIndex] = 1;
    },
  },
};
</script>

<style lang="sass" scoped>
#main
  min-width: 1200px

.info
  border-bottom: 2px solid #15595f
  span
    font-size: 12px
    letter-spacing: 1px
    color: #595757
    font-weight: bold
    font-family: "Microsoft Jheng Hei Bold"

.title
  span
    font-size: 18px
    letter-spacing: 2px
    line-height: 24px
    color: #4c4948
    font-weight: bold
    font-family: "Microsoft Jheng Hei Bold"
  p
    font-size: 14px
    letter-spacing: 1px
    line-height: 24px
    color: #4c4948
    font-weight: 300
    font-family: "Microsoft Jheng Hei Light"

.book_card
  img
    display: block
    height: 180px

#go_back_btn
  position: fixed
  right: 0
  bottom: 0

#bookInfo
  margin-left: 9rem
  width: 750px
  .title
    font-size: 26px
    letter-spacing: 3px
    line-height: 36px
    color: #15595f
    font-weight: bold
    font-family: "Microsoft Jheng Hei Bold"
  .sub_title
    font-size: 18px
    letter-spacing: 2px
    line-height: 24px
    color: #4c4948
    font-weight: 300
    font-family: "Microsoft Jheng Hei Light"
  hr
    margin-top: 1rem
    margin-bottom: 1rem
    border: 0
    border-top: 1px solid #15595f
    margin-left: 0px
  .date
    font-size: 18px
    letter-spacing: 2px
    line-height: 24px
    color: #4c4948
    font-weight: 300
    font-family: "Microsoft Jheng Hei Light"

#font_size
  a
    display: inline-block
    font-size: 22px
    letter-spacing: 2px
    line-height: 30px
    color: #6b6a6a
    font-family: "Microsoft Jheng Hei Regular"
  a.active
    background-color: #c2dada
    border-radius: 50%
    width: 30px
    height: 30px
    text-align: center

#author
  font-size: 14px
  letter-spacing: 1px
  line-height: 26px
  color: #4c4948
  font-weight: 300
  font-family: "Microsoft Jheng Hei Light"
  span
    display: inline
    border-bottom: 1px solid #4c4948

#content
  .title
    display: block
    text-align: center
    font-size: 24px
  .appendix
    color: #15595f
  a
    color: #15595f

.bottom_hr
  margin-top: 5rem !important

.ref_section
  word-break: break-all
  p
    color: #4c4948
</style>

<style scoped>
#content >>> h2 {
  display: block;
  text-align: center;
  font-size: 24px;
}
</style>
